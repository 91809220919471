<template>
  <div class="mb-3 mt-5 pt-3">
    <div class="row">
      <div
        :class="{
          [$style.card_expired]: true,
        }"
      >
        <div
          :class="{
            [$style.card__register__image]: true,
          }"
        >
          <img
            :src="expiredImage"
          />
        </div>

        <div class="d-flex justify-content-center align-items-center pbz-font subtitle-md-medium mt-5 mb-3" style="color: #1A1A1A !important">
          {{ $t('auth.emailExpired') }}
        </div>

        <div class="d-flex text-center justify-content-center align-items-center pbz-font body-md-regular my-3" style="color: #999999 !important">
          {{ $t('auth.expiredDescription') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userVerification } from '@/api/user'

export default {
  data () {
    return {
      expiredImage: require('@/assets/general/error/disconnect.png'),
    }
  },
  computed: {
    email () {
      return this.$store.state.user.email
    },
    loading () {
      return this.$store.state.user.laoding
    },
  },
  methods: {
    async resendEmail () {
      await userVerification({
        type: 'resend-email-verify',
        data: { user_uuid: this.$store.state.user.uuid },
      })
      .then(() => this.$router.push({ path: '/auth/reg-conf/email' }))
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
